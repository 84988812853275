import React, { Component } from 'react'
import { setGuide, setTitle } from "../../actions"
import { connect } from "react-redux"
import CanteenClient from "../../CanteenClient"
import { clearMessages, addMessage } from '@sapkk/app/actions';
import Loading from "@sapkk/app/components/Loading"
import { Link } from 'react-router-dom';
import { withRouter } from "react-router"
import LoadingButton from '../../components/LoadingButton';
import FilestoreClient from '../../FileStoreClient';

class SAPKulonbseg extends Component {
    constructor(props) {
        super(props)

        this.state = {
            diffs: undefined,
        }
    }

    componentDidMount() {
        if(this.props.history.location.pathname!=='/szamlazas/sap-szinkronizacio'){
            this.props.history.replace('/szamlazas/sap-szinkronizacio')
        }
        this.handleFetch()
    }

    handleFetch = ()=>{
        this.props.clearMessages()
        this.setState({
            fetch_in_progress: true
        },()=>{
            this.props.promise_error_handler(CanteenClient.getSapDiffs().then(diffs =>
                this.setState({
                    diffs: diffs,
                    fetch_in_progress: false
                })
            ))
        })
    }

    static field_name_conversion =  {
        om: 'oktatási azonosító',
        sap_customer_code: 'SAP vevőkód',
        name: 'név',
        country_code: 'országkód',
        zip_code: 'irányítószám',
        city_name: 'város',
        district: 'kerület',
        street_name: 'közterület neve',
        street_type: 'közterület',
        house_number: 'házszám',
        building: 'épület',
        staircase: 'lépcsőház',
        floor: 'szint',
        door: 'ajtó',
        bank_account_owner: 'bankszámlatulajdonos',
        bank_account_country_code: 'bankszámla országkód',
        bank_key: 'bankkulcs',
        bank_account_number: 'bankszámlaszám',
        iban: 'IBAN',
        e_invoice_status: 'e-számlázás'
    }

    handleDownload = () => {
        this.setState({
            downloading_in_progress: true
        }, () => {
            this.props.promise_error_handler(CanteenClient.reportDownload('/CanteenService/Report/sapDiff', {}).then((result) => {
                return FilestoreClient.download(result.hash)
            })).then(() => {
                this.setState({
                    downloading_in_progress: false
                })
            })
        })
    }

    render() {
        return (
            <React.Fragment>
                <div className={"not-authenticated"}>A törzsadat szinkronizáció a következő munkanappal történik!</div>
                <div className="page-subtitle">SAP-val való különbség</div>
                {
                    this.state.diffs!==undefined ? <React.Fragment>
                        <div className="block table-container">
                            <table className="striped sap-diff-table">
                                <thead>
                                    <th>Iskola</th>
                                    <th>Osztály</th>
                                    <th>Tanuló neve</th>
                                    <th>Oktatási azonosító</th>
                                    <th>Vevőkód</th>
                                    <th>SAP-ban megtalált</th>
                                    <th>Eltérő mezők</th>
                                </thead>
                                <tbody>
                                    { this.state.diffs.length !== 0 ? this.state.diffs.map(diff =>
                                        <React.Fragment>
                                            <tr>
                                                <td>{ diff.school_abbr }</td>
                                                <td>{ diff.class_name }</td>
                                                <td><Link to={'/tanulo-kezelese/T' + (-1*diff.student_id + '/adatok')}>{ diff.student_name }</Link></td>
                                                <td>{ diff.om }</td>
                                                <td>{ diff.sap_customer_code }</td>
                                                <td>{ diff.in_sap ? '✓' : '✗'}</td>
                                                <td>{ diff.diff_keys.map(k => SAPKulonbseg.field_name_conversion[k] || k).join(', ') }</td>
                                            </tr>
                                        </React.Fragment>
                                    ) : <tr className="table-empty-indicator"><td colSpan="5">Nincs különbség</td></tr> }
                                </tbody>
                            </table>
                        </div>
                        <div className="actions">
                            <LoadingButton loading={this.state.downloading_in_progress} onClick={this.handleDownload}>Letöltés</LoadingButton>
                        </div>
                    </React.Fragment> : <Loading loading_text=''/>
                }
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        promise_error_handler: state.app.promise_error_handler,
    }
}

const mapDispatchToProps = {
    setTitle: setTitle,
    setGuide: setGuide,
    clearMessages: clearMessages,
    addMessage: addMessage
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SAPKulonbseg))
